<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card>
      <v-card-title>
        Ajout d'une classification
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <ProviderSelector v-model="providerIdValue" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <SourceSelector v-model="sourceValue" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ClassificationTypeSelector v-model="typeValue" />
          </v-col>
        </v-row>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  Référence externe
                </th>
                <th class="text-center">
                  Nom externe
                </th>
                <th class="text-center">
                  -
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="index"
              >
                <td class="pa-1">
                  <v-text-field
                    v-model="item.reference"
                    hide-details
                    clearable
                    dense
                    outlined
                  />
                </td>
                <td class="pa-1">
                  <v-text-field
                    v-model="item.name"
                    hide-details
                    clearable
                    dense
                    outlined
                  />
                </td>
                <td>
                  <v-btn
                    icon
                    color="red lighten-1"
                    @click="removeItem(index)"
                  >
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td
                  colspan="3"
                  class="text-right"
                >
                  <v-btn
                    icon
                    color="blue lighten-1"
                    @click="addItem"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="submit()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClassificationTypeSelector from '@/components/base/ExternalCatalog/Input/ClassificationTypeSelector.vue'
import ProviderSelector from '@/components/base/ExternalCatalog/Input/ProviderSelector.vue'
import SourceSelector from '@/components/base/ExternalCatalog/Input/SourceSelector.vue'

export default {
  name: 'CreationModal',
  components: { ClassificationTypeSelector, SourceSelector, ProviderSelector },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    providerId: {
      type: String,
      default: null,
    },
    source: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      items: [{
        name: null,
        reference: null,
      }],
    }
  },
  computed: {
    openedValue: {
      get () { return this.opened  },
      set (value) { this.$emit('update:opened', value) },
    },
    providerIdValue: {
      get () { return this.providerId  },
      set (value) { this.$emit('update:providerId', value) },
    },
    sourceValue: {
      get () { return this.source  },
      set (value) { this.$emit('update:source', value) },
    },
    typeValue: {
      get () { return this.type  },
      set (value) { this.$emit('update:type', value) },
    },
  },
  methods: {
    close() {
      this.openedValue = false
      this.items = [{ name: null, reference: null }]
    },
    addItem() {
      this.items.push({ name: null, reference: null })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    submit() {
      this.$emit('submited', {
        providerId: this.providerId,
        source: this.source,
        type: this.type,
        items: this.items,
      })
      this.close()
    },
  },
}
</script>

<style scoped>
.item-line {
  border-bottom: #CCC 1px solid;
  background-color: white;
}

.item-line:first-child {
  border-top: #CCC 1px solid;
}
.item-line:nth-child(even) {
  background: #FAFAFA
}
</style>
